import { FC, ReactNode, useCallback, useEffect, useState } from 'react'

import WarningCircleIcon from '~/src/components/generic/PhosphorIcons/WarningCircleIcon'
import CheckCircleIcon from '~/src/components/generic/PhosphorIcons/CheckCircleIcon'
import WarningIcon from '~/src/components/generic/PhosphorIcons/WarningIcon'

import { NotificationMessage } from '~/src/components/shared/Notification/components/NotificationMessage'
import { Notification } from '~/src/components/shared/Notification/types'
import { getNotificationTimeLapsed } from '~/src/components/shared/Notification/helpers'

import colors from '~/config/colorsScheme'

import { RiskTier } from '~/__generated_types__/globalTypes'

type Props = {
  notification: Notification
}

export const iconMap: { [key in RiskTier]: ReactNode } = {
  [RiskTier.Risk]: <WarningIcon size="20" color={colors.yellow[600]} />,
  [RiskTier.Resolved]: <CheckCircleIcon size="20" color={colors.green[700]} />,
  [RiskTier.Critical]: <WarningCircleIcon size="20" color={colors.red[600]} />,
}

const NotificationContainer: FC<Props> = ({ notification }) => {
  const [timeLapsedSinceNotified, setTimeLapsedSinceNotified] = useState<
    string | undefined
  >(undefined)

  const getDifferenceWithCurrentTime = useCallback(() => {
    setTimeLapsedSinceNotified(getNotificationTimeLapsed(notification.timestamp))
  }, [notification.timestamp])

  useEffect(() => {
    getDifferenceWithCurrentTime()
  }, [getDifferenceWithCurrentTime])

  useEffect(() => {
    const interval = setInterval(() => {
      getDifferenceWithCurrentTime()
    }, 60000)

    return () => clearInterval(interval)
  }, [getDifferenceWithCurrentTime])

  return (
    <div className="flex items-center justify-start pl-6">
      <div data-testid={notification.tier.toString() + '-icon'}>
        {iconMap[notification.tier]}
      </div>
      <NotificationMessage
        notification={notification}
        timeSinceNotified={timeLapsedSinceNotified}
        displayType="dropdown"
      />
    </div>
  )
}

export default NotificationContainer
