import { User } from '~/hooks/useAuthentication/types'

import { UserRole } from '~/__generated_types__/globalTypes'

export const isRadiusUser = (user: { role: UserRole } | null | undefined) => {
  if (!user) {
    return false
  }

  return [UserRole.Operator, UserRole.Sysadmin].includes(user.role)
}

export const isUserSysadmin = (user: User) => {
  if (!user) {
    return false
  }

  return user.role === UserRole.Sysadmin
}

export const isTMCustomerUser = (user: User) => {
  if (!user) {
    return false
  }

  return user.role === UserRole.Customer && user.customer?.type === 'TM'
}
