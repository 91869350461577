import * as Sentry from '@sentry/nextjs'
import { Replay } from '@sentry/react'
const isDeployment =
  process.env.NEXT_PUBLIC_ENV === 'preview' ||
  process.env.NEXT_PUBLIC_ENV === 'production'

export const setUser = (id: string, email: string, username?: string): void => {
  // Only add username if it exists
  const user = { id, email, ...(username && { username }) }
  if (isDeployment) {
    const client = Sentry.getCurrentHub().getClient()
    if (client && !client.getIntegration(Replay)) {
      client.addIntegration?.(
        new Replay({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
        })
      )
    }

    Sentry.setUser(user)
  } else {
    console.log(`Set Sentry user: ${user}`) // eslint-disable-line no-console
  }
}
