import { gql } from '@apollo/client'

export const GET_AUTHENTICATED_USER = gql`
  query GetAuthenticatedUser {
    currentUser {
      ... on User {
        id
        uuid
        fullname
        isVerified
        isSuperuser
        profilePictureUrl
        emails {
          email
          isVerified
        }
        role
        phoneNumbers {
          phoneNumber
          isVerified
        }
        settings {
          updatedAt
          favoriteCostCenters {
            id
            name
            radiusVersion
          }
          homePage
        }
        customer {
          type
        }
      }
    }
  }
`
export const LOGOUT = gql`
  mutation LogoutAuthenticatedUser {
    logout {
      id
      role
    }
  }
`
