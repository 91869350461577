import { GetNotificationsForUserQuery } from '~/src/components/shared/Notification/__generated_types__/queries.gql'

import { RiskTier } from '~/__generated_types__/globalTypes'

export type NotificationDisplayType = 'dropdown' | 'toast'

export enum View {
  ListView = 'ListView',
  MapView = 'MapView',
  DetailView = 'DetailView',
  DVDView = 'DVDView',
}

export type UseClickNotificationReturnType = [
  createHref: (notification: Notification) => string
]

export type UseMarkNotificationsAsReadReturnType = [
  readAt: (notificationIds: Array<Notification>) => void
]

export type NotificationProps = Notification

export type Notification = GetNotificationsForUserQuery['getNotificationsForUser'][0]

export const notificationTierRank: { [key in RiskTier]: number } = {
  [RiskTier.Resolved]: 0,
  [RiskTier.Risk]: 1,
  [RiskTier.Critical]: 2,
}

export type Color = 'red' | 'yellow'
