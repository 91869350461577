import { createContext } from 'react'

import { User } from '~/hooks/useAuthentication/types'
import {
  LogoutAuthenticatedUserMutation,
  LogoutAuthenticatedUserMutationVariables,
} from '~/hooks/useAuthentication/__generated_types__/queries.gql'

import type { MutationFunctionOptions, FetchResult } from '@apollo/client'

export const AuthenticatedUserContext = createContext<
  Readonly<
    [
      User,
      (
        options?: MutationFunctionOptions<
          LogoutAuthenticatedUserMutation,
          LogoutAuthenticatedUserMutationVariables
        >
      ) => Promise<FetchResult<LogoutAuthenticatedUserMutation>>,
      { loading: boolean; refetch: () => void }
    ]
  >
>([
  null,
  () => Promise.resolve({} as FetchResult<LogoutAuthenticatedUserMutation>),
  { loading: false, refetch: () => ({}) },
])
