import { gql } from '@apollo/client'

const GET_NOTIFICATIONS_FOR_USER = gql`
  query GetNotificationsForUser($timeFloor: DateTime) {
    getNotificationsForUser(timeFloor: $timeFloor) {
      ... on Notification {
        id
        loadId
        legIdx
        stopIdx
        readAt
        tier
        type
        timestamp
        load {
          id
          legs {
            id
            stops {
              id
            }
          }
          reference
        }
        driver {
          fullname
          id
        }
      }
    }
  }
`

const SUBSCRIBE_NOTIFICATIONS = gql`
  subscription SubscribeUserNotifications($userId: Int!) {
    subscribeUserNotifications(userId: $userId) {
      ... on Notification {
        id
        loadId
        legIdx
        stopIdx
        readAt
        tier
        type
        timestamp
        load {
          id
          legs {
            id
            stops {
              id
            }
          }
          reference
        }
        driver {
          fullname
          id
        }
      }
    }
  }
`

const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationsAsRead($notificationIds: [Int!]!) {
    markNotificationsAsRead(notificationIds: $notificationIds) {
      ... on MarkedRead {
        readAt
      }
    }
  }
`

export {
  GET_NOTIFICATIONS_FOR_USER,
  SUBSCRIBE_NOTIFICATIONS,
  MARK_NOTIFICATIONS_AS_READ,
}
