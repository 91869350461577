type LocationUrlProps = {
  locationId: bigint
}

type UrlProps = {
  id: number
}

export const OperatorLoadBasePath = '/loadboard'
export const CustomerLoadBasePath = '/rydershare/visibility'
export const ShipmentBasePath = '/shipments'
export const RydershareBasePath = 'https://www.rydershare.com'

const Urls = {
  location: () => '/?tab=location',
  locationById: ({ locationId }: LocationUrlProps) =>
    `${Urls.location()}&id=${locationId}`,
  login: () => '/login',
  operatorLoad: ({ id }: UrlProps) => `${OperatorLoadBasePath}/${id}`,
  customerLoad: ({ id }: UrlProps) => `${CustomerLoadBasePath}/${id}`,
  shipment: ({ id }: UrlProps) => `${ShipmentBasePath}/${id}`,
  shipmentLoad: ({ id, loadId }: { id: number; loadId: number }) =>
    `${ShipmentBasePath}/${id}/load/${loadId}`,
  rydershare: () => `${RydershareBasePath}/landing`,
}

export { Urls }
