import { ArrowPlacement, Placement } from '~/src/components/generic/Tooltip/types'
import Tooltip from '~/src/components/generic/Tooltip'

type PositionProps = {
  [key in Placement]: string
}

export const getTooltipContainerClass = (placement: Placement) => {
  const positionMapping: PositionProps = {
    top: 'bottom-[calc(100%_+_10px)]',
    bottom: 'top-[calc(100%_+_10px)]',
    left: 'right-[calc(100%_+_10px)]',
    right: 'left-[calc(100%_+_10px)]',
  }
  let twClass = ''

  if (placement === 'top' || placement === 'bottom') {
    twClass += 'left-1/2 -translate-x-6'
  } else {
    twClass += 'top-1/2 -translate-y-1/2'
  }

  return twClass + ' ' + positionMapping[placement]
}

export const getTooltipArrowClass = (
  placement: Placement,
  arrowPlacement: ArrowPlacement
) => {
  const positionMapping: PositionProps = {
    top: 'top-full border-t-8 border-t-black/75',
    bottom: 'bottom-full border-b-8 border-b-black/75',
    left: 'left-full border-l-8 border-l-black/75',
    right: 'right-full border-r-8 border-r-black/75',
  }
  let twClass = ''

  if (placement === 'top' || placement === 'bottom') {
    twClass += '-translate-x-2 border-x-8 border-x-transparent'
    if (arrowPlacement === 'left') {
      twClass += ' left-6'
    } else if (arrowPlacement === 'right') {
      twClass += ' right-6'
    }
  } else {
    twClass += 'top-1/2 -translate-y-1/2 border-y-8 border-y-transparent'
  }

  return twClass + ' ' + positionMapping[placement]
}

export const addHeaderTooltip = (tooltipText: string, headerText: string) => {
  return (
    <div className="flex">
      <Tooltip
        placement="bottom"
        className="min-content w-36 normal-case"
        content={<div>{tooltipText}</div>}
      >
        {headerText}
      </Tooltip>
    </div>
  )
}
