import { FC } from 'react'
import { twMerge } from 'tailwind-merge'
import { isNil } from 'ramda'
import Link from 'next/link'

import CircleIcon from '~/src/components/generic/PhosphorIcons/CircleIcon'
import ArticleIcon from '~/src/components/generic/PhosphorIcons/ArticleIcon'
import UserSquareIcon from '~/src/components/generic/PhosphorIcons/UserSquareIcon'
import Button from '~/src/components/generic/Button'
import { useToast } from '~/src/components/generic/Toast/hook/useToast'

import {
  NotificationDisplayType,
  Notification,
} from '~/src/components/shared/Notification/types'
import {
  NotificationDisplayTier,
  NotificationDisplayViolation,
} from '~/src/components/shared/Notification/constants'
import useClickNotification from '~/src/components/shared/Notification/hooks/useClickNotification'
import useMarkNotificationsAsRead from '~/src/components/shared/Notification/hooks/useMarkNotificationsAsRead'
import { getNotificationTimeLapsed } from '~/src/components/shared/Notification/helpers'

import colors from '~/config/colorsScheme'
import { MixpanelEvent, track } from '~/clients/mixpanelClient'

type Props = {
  notification: Notification
  displayType: NotificationDisplayType
  timeSinceNotified?: string
}

export const NotificationMessage: FC<Props> = ({
  notification,
  displayType,
  timeSinceNotified,
}) => {
  const toast = useToast()
  const [createHref] = useClickNotification()
  const [markNotificationsAsRead] = useMarkNotificationsAsRead()

  const trackNotificationSelected = (notification: Notification) => {
    track(MixpanelEvent.NotificationSelected, {
      source: 'Toast',
      notificationType: notification.type,
      notificationTier: notification.tier,
      age: getNotificationTimeLapsed(notification.timestamp),
      load: notification.load?.reference,
      driver: notification.driver?.fullname,
    })
  }

  const onNotificationClickHandler = (clickedNotification: Notification) => {
    trackNotificationSelected(clickedNotification)
    isNil(clickedNotification.readAt) && markNotificationsAsRead([clickedNotification])
    toast.remove(clickedNotification.id)
  }

  if (displayType === 'dropdown' && !notification.timestamp) return null

  const displayTypeDetails =
    displayType === 'toast' ? (
      <Button
        variant="link"
        className="text-grey-800 px-2 py-1 text-xs font-extrabold hover:bg-gray-75"
        onClick={() => onNotificationClickHandler(notification)}
      >
        <Link href={createHref(notification)}>View</Link>
      </Button>
    ) : (
      <div className="flex justify-end">
        <div className="flex items-center">
          <div
            className={twMerge(
              'text-xs font-medium text-gray-900',
              !isNil(notification.readAt) && 'pr-2 font-normal'
            )}
          >
            {timeSinceNotified}
          </div>
          {isNil(notification.readAt) && (
            <div role="read-badge" className="pl-3">
              <CircleIcon size="8" color={colors.red[600]} weight="fill" />
            </div>
          )}
        </div>
      </div>
    )

  return (
    <div
      data-testid="notification-message"
      className={twMerge(
        'flex w-80 items-center justify-between',
        displayType === 'toast' && 'w-68'
      )}
    >
      <div className={twMerge(displayType === 'dropdown' && 'pl-3')}>
        <div className="flex items-center justify-start space-x-2">
          <div className="text-sm font-extrabold">
            {NotificationDisplayViolation[notification.type]}
          </div>
          <div className="pt-1 text-4xs font-bold uppercase text-gray-400">
            {NotificationDisplayTier[notification.tier]}
          </div>
        </div>
        <div className="align-center flex justify-start space-x-2">
          <div
            className={twMerge(
              'flex items-center justify-start space-x-1 text-xs font-extrabold text-gray-400',
              !isNil(notification.readAt) && 'font-medium'
            )}
          >
            <ArticleIcon size="16" color={colors.gray[400]} />
            <span>{notification.load?.reference}</span>
          </div>
          {notification.driver?.fullname && (
            <div className="flex space-x-1">
              <UserSquareIcon size="16" color={colors.gray[400]} />
              <span
                className={twMerge(
                  'w-16 truncate text-xs font-extrabold text-gray-400',
                  !isNil(notification.readAt) && 'font-medium',
                  displayType === 'dropdown' && 'w-28'
                )}
              >
                {notification.driver.fullname}
              </span>
            </div>
          )}
        </div>
      </div>
      {displayTypeDetails}
    </div>
  )
}
