import { useMutation } from '@apollo/client'
import { useCallback } from 'react'

import {
  Notification,
  UseMarkNotificationsAsReadReturnType,
} from '~/src/components/shared/Notification/types'
import { MARK_NOTIFICATIONS_AS_READ } from '~/src/components/shared/Notification/queries'
import {
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables,
} from '~/src/components/shared/Notification/__generated_types__/queries.gql'
import { useNotification } from '~/src/components/shared/Notification/hooks/useNotification'

const useMarkNotificationsAsRead = (): UseMarkNotificationsAsReadReturnType => {
  const notificationContext = useNotification()

  const [executeQuery] = useMutation<
    MarkNotificationsAsReadMutation,
    MarkNotificationsAsReadMutationVariables
  >(MARK_NOTIFICATIONS_AS_READ)

  const markNotificationsAsRead = useCallback(
    (notificationsToMark: Array<Notification>) => {
      const notificationIds = notificationsToMark.map((notification) => notification.id)

      executeQuery({
        variables: {
          notificationIds: notificationIds,
        },
        onCompleted: (data) => {
          const notifications = notificationContext?.notifications

          if (notificationIds.length === 1) {
            notifications &&
              notificationContext.update(
                notifications.map((notification) =>
                  notification.id === notificationIds[0]
                    ? { ...notification, readAt: data.markNotificationsAsRead.readAt }
                    : notification
                )
              )
          } else {
            notifications &&
              notificationContext.update(
                notifications.map((notification) => ({
                  ...notification,
                  readAt: data.markNotificationsAsRead.readAt,
                }))
              )
          }
        },
      })
    },
    [executeQuery, notificationContext]
  )

  return [markNotificationsAsRead]
}

export default useMarkNotificationsAsRead
