import { FC } from 'react'
import { Circle } from '@phosphor-icons/react'

import { IconPropsType } from '~/src/components/generic/PhosphorIcons/types'

const Icon: FC<IconPropsType> = ({ color, size, weight, mirrored, className }) => (
  <Circle
    color={color}
    size={size}
    weight={weight}
    mirrored={mirrored}
    className={className}
  />
)

export default Icon
