import { useSearchParams } from 'next/navigation'
import { isNil } from 'ramda'

import {
  UseClickNotificationReturnType,
  Notification,
} from '~/src/components/shared/Notification/types'

import { Urls } from '~/constants/urls'

const useClickNotification = (): UseClickNotificationReturnType => {
  const searchParams = useSearchParams()

  const createQueryString = (legIdx?: number | null, stopIdx?: number | null) => {
    const params = new URLSearchParams(searchParams)
    if (!isNil(legIdx)) params.set('leg', legIdx.toString())
    if (!isNil(stopIdx)) params.set('stop', stopIdx.toString())

    return params.toString()
  }

  const createHref = (notification: Notification) => {
    const { legIdx, stopIdx, loadId } = notification
    const queryString = createQueryString(legIdx, stopIdx)

    return loadId ? `${Urls.operatorLoad({ id: loadId })}?${queryString}` : ''
  }

  return [createHref]
}

export default useClickNotification
